//====== mission statement
.home-missionStatement{
	text-align: center;
	padding: 130px 0 280px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	position: relative;
	&:after{
		content: "";
		background: rgba(255,255,255,0.85);
		top: 0;
		bottom:0;
		left:0;
		left:0;
		width: 100%; 
		height: 100%;
		position: absolute;
	} 
	.wrapper{
		position: relative;
		z-index: 1;
	}
	h2{
		font-size: 5rem;
		margin-bottom: 10px;
	}
	h3{
		color: $blue;
		font-weight: 100;
		margin-bottom: 50px;
	}
	p{
		max-width: 850px;
		margin: 0 auto;
		font-size: 2.8rem;
	}
}

.white-bar{
	background: rgba(255,255,255,0.8);
	padding: 10px 0; 
	h1{
		font-size: 3.5rem; 
		margin-bottom: 5px;
		text-align: center;
		line-height: 1.3;
	}

	.header-content{
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		p{
			max-width: 710px;
			margin-right: 20px;
		}
	}

	.team-button{
		font-size: 2.6rem;
	}
}



// ======stats section
.home-stats{
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 100px 0;

	.flickity-slider{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
	}

	@media all and (-ms-high-contrast:none){
		.flickity-slider{
	    align-items: flex-start; 
		}
	 }

	.flickity-viewport{
		width: 100%;
		height: 422px !important;

	}
	.home-stat-container{
		padding:0;
		margin:0;
	}

	.mobile-buttons{
		display: none;
	}

	/* enable Flickity by default */
	.home-stat-container:after {
	  content: 'flickity';
	  display: none; /* hide :after */
	}

	.home-stat{
		background: rgba(255,255,255,0.8);
		border: 1px solid #333333;
		box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
		border-radius: 5px;
		width: 32%;
		margin: 0 0.3%;
		padding: 50px 60px;
		text-align: center;
		transition: 0.3s;
		box-shadow: 5px 5px 5px rgba(0,0,0,0.2), -5px -5px 5px rgba(0,0,0,0.2);
		min-height: 335px;
		.large-stat{
			font-size: 5.7rem;
			font-weight: 100;
			line-height: 1;
		}

		.small-stat{
			font-weight: 600;
			font-size: 2.4rem;
			padding: 40px 0;
		}
		a{
			font-weight: bold;
			color: $blue;
			text-decoration: none;
			margin-top: 40px;
			display: inline-block;
		}
		span{
			display: block;
		}
		&:before{
			display: none;
		}
	}

	.home-stat.is-selected{
		// flex-grow: 1;
		padding: 70px 60px 70px;
		background: white;
		.large-stat{
			color: $yellow;
			font-size: 7rem;
		}
			
	}

	.mobile-swipe{
		display: none;
		color: white;
		font-size: 2.8rem;
		font-weight: bold;
		text-transform: uppercase;
		margin-top: 0;
		text-align: center;
	}

}

.journey{
	min-height: 800px;
	align-items: stretch;
	@media all and (-ms-high-contrast:none){
		height: 800px;
	 }
	.journey-content{
		max-width: 450px;
		margin-right: 55px;
		float: right;
	}
	.journey-left{
		background: url(img/subwaybg.svg);
		background-position: left;
		background-attachment: fixed;
		background-repeat: no-repeat;
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	h2{
		font-weight: 100;
		font-size: 4rem;
		line-height: 1.2;
	}
	p{
		font-size: 2rem;
		margin-top: 24px;
	}
	a{
		@extend .teal-button;
		font-size: 3rem;
		margin-top: 60px;
	}

	.journey-right{
		width: 50%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
}

// donate section
.home-donate-section{
	background-color: $yellow;
	padding: 150px 0;
	.mobile-donate{
		display: none;
	}
	.wrapper{
		padding: 60px 100px;
		background: white;
		overflow: hidden;
	}

	p{
		margin-top: 65px;
		margin-bottom: 50px;
		margin-right: 10px;
	}


	h2{
		font-size: 4rem;
		font-weight: 100;
		line-height: 1.2;
		margin-bottom: 50px;
	}

	img{
		width: 90%;
		transition:0.3s;
		position: relative;
	}
	.home-donate{
		width: 50%;
	}

	@keyframes pay{
		0%{
			transform: rotate(0);
			left:-50px;
			top: 0;

		}

		50%{
			transform: rotate(20deg);
			left: 80px;
			top: 40px;
		}

		100%{
			transform: rotate(0);
			left:0;
			top: 0;
		}
	}

	@keyframes jiggle{
		0%{
			transform: rotate(0);
		}
		50%{
			transform: rotate(-10deg);
		}
		100%{
			transform: rotate(0);
		}
	}

	img.hand{
		opacity:0;
	}
	img.hand.rotate{
		animation: pay 1.5s;
		opacity:1;
	}

	img.heart{
		opacity:0;
	}
	img.rotate.heart{
		animation: jiggle 1.5s;
		opacity:1;
	}
}

// ===== blog section =====
.home-featuredBlog{
	padding: 90px 0 90px;
	h2{
		font-weight: 4rem;
		font-weight: 600;
		text-align: center;
		line-height: 1.1;
	}
	li{
		&:before{
			display: none;
		}
	}
	.home-featuredBlog-container{
		margin: 0;
		padding: 0;
		margin-top: 80px;
	}
	.home-featuredBlog-post{
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		margin-bottom: 80px;
		.flickity-slider, .flickity-viewport{
			width: 100%;
		}
	}

	.home-blog-content{
		width: 55%;
	}
	h3{
		font-size: 3rem;
		color: $blue;
		font-weight: 600;
		margin-bottom: 15px;
	}
	p{
		margin-bottom: 15px;
	}

	.home-blog-image{
		padding-right: 30px;
		width: 45%;
	}

	.flickity-page-dots .dot{
		background: #CFCDCD;
		width: 25px;
		height: 25px;
		opacity: 1;
	}

	.flickity-page-dots .dot.is-selected{
		background: $yellow;
	}

}



// ====== media queries

// note below is a height media query
@media (max-height: 750px){
	.home-stats .home-stat.is-selected{
		padding: 40px 40px;
	}

	.home-stats .home-stat{
		padding: 20px 40px;
	}

	.home-stats .home-stat .small-stat{
		padding: 20px 0;
	}
}

@media (max-width: $xl){

	.main-nav{
		transition: 0;
	}
	.home-stats .home-stat{
		margin: 0 -50px;
		padding: 30px 30px;
		transition: 0.3s;
		width: 40%;
	}

	.home-stats .home-stat.is-selected{
		z-index: 1 !important;
		padding: 60px 30px;
	}

	.header-content .teal-button{
		padding: 10px 20px;
		font-size: 2.5rem;
		min-width: 210px;
	}

	.home-stats .home-stat .small-stat{
		padding: 20px 0;
	}

	.home-stats .flickity-viewport{
		height: 525px !important;
	}
	.journey .journey-content{
		margin-right: 25px;
		margin-left: 25px;
	}

	.journey h2{
		font-size: 3rem;
	}
	.journey a{
		font-size: 2.4rem;
	}

	.home-donate-section{
		padding: 2.5% 0;
	}

	.home-donate-section .wrapper{
		padding: 60px 40px;
	}

	.home-featuredBlog{
		padding: 55px 0 70px;
	}

	.home-featuredBlog .home-featuredBlog-container{
		margin-top: 60px;
	}

}

@media (max-width: $lg){
	.home-featuredBlog .home-featuredBlog-post{
		flex-direction: column;
	}

	.home-featuredBlog .home-blog-content, .home-featuredBlog .home-blog-image{
		width: 100%;
		text-align: center;
		padding-right: 0;
	}

	.home-featuredBlog p{
		text-align: left;
	}

	.home-blog-image {
		margin-bottom: 60px;
	}

	.white-bar h1{
		font-size: 3rem;
	}

	.home-stats .home-stat{
		min-height: 355px;
	}


}

@media (max-width: $md){

	.home-missionStatement{
		padding: 100px 0 200px;
		position: relative;
	}

	.home-missionStatement h2{
		font-size: 4rem;
		margin-bottom: 0;
	}

	.home-missionStatement h3{
		margin-bottom: 50px;
	}

	.journey .journey-left, .journey .journey-right{
		width: 100%;
		padding: 65px 0 80px;
	}

	.journey{
		@media all and (-ms-high-contrast:none){
	    height: auto;
	    min-height: auto;
	 	}
	}

	.journey .journey-right{
		min-height: 630px;
		@media all and (-ms-high-contrast:none){
	    display: none;
	 	}
	}

	.journey .journey-content{
		margin-right: 0;
		margin-left: 0;
		float: none;
		text-align: center;
		max-width: 97%;
		margin: 0 auto;
	}

	.journey p{
		text-align: left;
	}

	.journey{
		flex-direction: column;
	}
	
	.home-donate-section .red-button{
		font-size: 2.5rem;
		padding: 17px 20px;
	}

	.white-bar h1{
		font-size: 2.5rem;
	}


}

@media (max-width: 700px){

	.home-stats .mobile-buttons{
		display: flex;
		justify-content: space-between;
		margin: 0;
		padding: 0;
		 .home-stat{
			margin: 0;
			order: 0;
			width: 49%;
			padding: 30px 15px;
		}

		.home-stat.is-selected{
			display: none;
			width: 100%;
		}
	}

	.home-stats .home-stat.is-selected .large-stat{
		font-size: 5rem;
		line-height: 1;
	}

	.home-stats .home-stat .large-stat{
		font-size: 4rem;
	}

	.home-stats .home-stat .small-stat{
		font-size: 2rem;
		padding: 8px 0;
	}

	.home-stats .home-stat p{
		font-size: 1.4rem;
	}

	.home-stats .home-stat{
		min-height: auto;
	}

	 .home-stats .home-stat-container .home-stat, .home-stat-container .home-stats .home-stat.is-selected{
		flex-grow: 1;
		width: 100%;
		padding: 30px 25px;
		margin-left: 0;
		margin-right: 0;
		 p{
		 	font-size: 1.6rem;
		 }
	}
	.home-stats .mobile-swipe{
		display: block;
		margin: 0 auto;
		text-shadow: 0px 2px 4px rgba(0,0,0,0.4);

	}

	.home-stats{
		padding: 30px 0 30px;
	}

	.white-bar{
		padding: 10px 0 10px;
	}

	.white-bar h1{
		font-size: 2.3rem;
		margin-bottom: 0;
		line-height: 1.2;
	}

	.white-bar .header-content p{
		max-width: 100%;
		margin-right: 0;
		font-size: 1.5rem;
		margin: 6px 0 10px;
		line-height: 1.2;
		margin-bottom: 0;
	}

	.white-bar .header-content .teal-button{
		font-size: 1.6rem;
		padding: 3px 10px;
		min-width: 160px;
	}
	.home-stats .flickity-viewport{
		height: 285px !important;
	}

	.home-stats .home-stat a{
		margin-top: 25px;
	}

}

@media (max-width: $sm){
	.home-missionStatement h2{
		font-size: 3.4rem;
	}

	.home-missionStatement h3{
		font-size: 2.4rem;
		margin-bottom: 30px;
	}

	.home-missionStatement p{
		font-size: 1.9rem;
	}
	.home-missionStatement{
		padding: 30px 0 80px;
	}

	.journey .journey-right{
		min-height: 510px;
		@media all and (-ms-high-contrast:none){
		height: 510px;
	 }
	}
	.journey p{
		font-size: 2.2rem;
	}

	.home-donate-section .home-donate, .home-donate-section .home-donate{
		display: none;
	}

	.home-donate-section .mobile-donate{
		display: block;
	}

	.home-donate-section img{
		max-width: 175px;
		display: block;
	}

	.donate-image-container{
		max-width: 400px;
		margin: 0 auto;
	}

	.home-donate-section img:last-of-type{
		float: right;
	}

	.home-donate-section .red-button{
		width: 100%;
		text-align: center;
	}

	.home-blog-image{
		margin-bottom: 20px;
	}

	.home-featuredBlog .home-featuredBlog-post{
		margin-bottom: 40px;
	}

	.white-bar h1{
		font-size: 2rem;
	}

	.white-bar .header-content p{
		font-size: 1.4rem;
		margin: 3px 0 7px;
	}

	.white-bar .header-content .teal-button{
		font-size: 1.3rem;
		min-width: 140px;
	}

	.home-donate-section .page-template-page-journey .bottom-section .links a:nth-of-type(2), .home-donate-section .red-button, .page-template-page-journey .bottom-section .links .home-donate-section a:nth-of-type(2){
		font-size: 2rem;
		padding: 7px 30px;
	}

	.home-stats .flickity-viewport{
		height: 265px !important;
	}

	.home-stats .home-stat .large-stat{
		font-size: 3rem;
	}

	.white-bar{
		padding: 5px 0;
	}

	.white-bar h1{
		font-size: 1.6rem;
	}


}

@media (max-width: $xs){
	.journey a{
		width: 100%;
	}

	.journey .journey-right{
		min-height: 390px;
		@media all and (-ms-high-contrast:none){
		    display: none;
		 }
	}

	@keyframes smallPay{
		0%{
			transform: rotate(0);
			left:-50px;
			top: 0;

		}

		50%{
			transform: rotate(20deg);
			left: 10px;
			top: 10px;
		}

		100%{
			transform: rotate(0);
			left:0;
			top: 0;
		}
	}


	.home-donate-section img.hand.rotate{
		animation: smallPay 1.5s;
	}

	.home-featuredBlog{
		width: 100%;
	}
	.home-blog-content{
		max-width: 97%;
		margin: 0 auto;
	}

	.home-featuredBlog p{
		margin-bottom: 40px;
	}

	.home-blog-content a{
		width: 100%;
		display: block;
		font-size: 2.5rem;
	}

	.white-bar h1{
		font-size: 1.6rem;
	}
}