.page-template-page-journey {

	.journey-page.wrapper,
	.journey-block .wrapper {
		max-width: 1200px;
	}

	.journey-page {
		position: relative;
		&:after {
			content: "";
			position: absolute;
			z-index: -1;
			top: -150px;
			bottom: 250px;
			right: -150px;
			height: 100vh;
			background: url(img/subwaybg.svg);
			background-repeat: repeat-y;
			width: 500px;
			opacity: .5;
		}
	}

	.journey-block.story {
		position: relative;
	}

	.journey-block.story:nth-child(even) {
		&:before {
			content: "";
			position: absolute;
			opacity: .5;
			z-index: -1;
			top: -150px;
			bottom: 250px;
			left: -150px;
			background: url(img/subwaybg.svg);
			background-repeat: repeat-y;
			width: 500px;
		}
	}

	.journey-block.story:nth-child(odd) {
		&:after {
			content: "";
			position: absolute;
			z-index: -1;
			top: -150px;
			bottom: 250px;
			right: -150px;
			background: url(img/subwaybg.svg);
			background-repeat: repeat-y;
			width: 500px;
			opacity: .5;
		}
	}

	.journey-page .journey-block:nth-of-type(4) {
		&:before {
			display: none;
		}
		&:after {
			content: "";
			position: absolute;
			z-index: -1;
			top: -150px;
			bottom: 250px;
			right: -150px;
			background: url(img/subwaybg.svg);
			background-repeat: repeat-y;
			width: 500px;
			opacity: .5;
		}
	}


	.stay-small {
		position: fixed !important;
		top: 120px !important;
	}

	.stay-large {
		position: fixed !important;
		top: 240px !important;
	}
	
	.stay-fade {
		opacity: 0;
	}


	.top-nav.fixed, header.fixed{
		position: static;
	}

	@keyframes fadeIn{
		0%{
			opacity:0;
		}

		100%{
			opacity: 1;
		}
	}

	.top{
		position: fixed;
		z-index: 11;
		right: 30px;
		bottom: 40px;
		text-transform: uppercase;
		text-decoration: none;
		text-align: center;
		font-size: 1.2rem;
		color: #8B8989;
		line-height: 1;

		span{
			color: $blue;
			display: block;
			font-size: 2rem;
			line-height: 1;
		}
	}

	.internal-page .hero h1{
		background: none;
		color: white;
		font-size: 6rem;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
		transition: 0.3s;
		opacity:0;
		z-index: 10;
		position: relative;
		animation: 1s fadeIn forwards 1s;
	}

	.alignfull .wrapper{
		max-width: 910px;
		strong {
			font-weight: 100;
		}
		p{
			font-size: 2.8rem;
			text-shadow: 1px 1px 5px black;
			max-width: 100%;
			font-weight: 100;
			margin: 0 auto;
		}
	}

	.internal-page .hero{
		padding:0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.top-height{
		padding-top: 0;
	}

	.top-nav.fixed .logo img, header.fixed .logo img{
		height: auto;
	}

	// HEADER WORK

	header {
		padding: 0px;
		.logo {
			text-align: center;
			a {
				display: inline-block;
			}
		}
	}

	section.hero {
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		background-attachment: fixed;
		min-height: 80vh;
		position: relative;
			@media all and (-ms-high-contrast:none){
		    height: 80vh;
		 	}
		&:after{
			content: "";
			position: absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			background: rgba(0,0,0,0.3);
			opacity: 0;
			transition: 0.3s;
			animation: 1.5s fadeIn forwards;
		}
	}

	main {
		overflow: hidden;
	}

	.journey-page {
		position: relative;
		padding-top: 150px;
	}
	
	.journey-item {
		margin-bottom: 150px;
	}

    .journey-title {
		margin: 0px auto 150px auto;
        text-align: center;
		text-transform: uppercase;
		position: relative;
		z-index: 2;
		pointer-events: none;
		min-height: 265px;
        h2 {
            font-size: 7rem;
			transition: color 1s ease-in-out;
			line-height: 1;
			margin-bottom: 10px;
        }
        h2.go-active {
            color: rgba(0,0,0,0.1);	
		}
		h3 {
			line-height: 1.2;
			font-size: 2.2rem;
			max-width: 750px;
			margin: 0 auto;
			text-transform: initial;
		}
	}
	

	//  JOURNEY LIST ITEMS
	.sticky-scroll-section {
		background: white;
		.sticky-circle-container{
			text-align: center;
		}
		.sticky-circle{
			position: absolute;
			top: -10px;
			z-index: 1;
			left: 50%;
			transform: translate(-50%);
			circle{
				fill: none;
				stroke: none;
				transition: 0.3s;
			}
		}
		.sticky-circle-container.visible{

			.sticky-circle circle{
				fill: inherit;
				stroke: inherit;
			}
		}

		&:after{
			content: "";
			width: 100%;
			height: 80px;
			background: white;
			position: absolute;
			top: 0px;
			width: 100%;
		}
	}

    .journey-sub-items{
		margin:0;
		padding:0;
		display: flex;
		justify-content: space-between;
		width: 100%;
		position: relative;
		z-index: 10;
		padding-top: 200px;
		li{
			position: relative;
			flex: 1;
			.title-line{
				width: 100%;
				height: 150px;
				overflow: hidden;
				top: -230px;
				svg{
					height: 90% !important;
				}
			}
			&:before{
				display: none;
			}
			.is-selected-svg{
				position: absolute;
				top: -300px;
				transition: top 0.3s;
				opacity:0;

			}
			svg.top-circle circle {
				opacity: 1;
				transition: all .3s ease-in-out;
				fill: white;
			}
		}

		li.is-selected {

			svg.top-circle circle {
				opacity: 1;
				fill: inherit;
			}
		}
		h3{
			font-size: 1.8rem;
			margin-top: 25px;
			line-height: 1.2;
			padding: 0 4px;
			text-transform: uppercase;
		}
		.title-line {
			text-align: center;
		}
		.title-circle {
			text-align: center;
			cursor: pointer;
			width: 100%;
		}
		.title-draw-line {
			position: absolute;
			left: 0;
			top: 50%;
			height: 10px;
			display: none;
		}
	}

	.journey-sub-items-sub{
		margin:0;
		padding:0 100px;
		.flickity-viewport{
			width: 100%;
		}
		img {
			max-width: 200px;
			max-height: 200px;
			border-radius: 50%;
		}
	}

    .journey-sub-item-sub-content{
		padding: 20px 0px 0px 0px;
		width: 100%;
		&:before{
			display: none;
		}
		h4{
			text-align: left;
			font-size: 3rem;
			margin-bottom: 20px;
			color: black;
		}
		.flex {
			justify-content: space-between;
			align-items: center;
		}
		.journey-subItem-image {
			width: 200px;
			height: 200px;
			border-radius: 50%;
			margin-right: 50px;
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
		.journey-subItem-content {
			flex: 1 0 auto;
			width: calc(100% - 300px);
		}
	}

	.sticky-scroll-section.pinned{
				
			.is-selected {
				.is-selected-svg{
				top: -150px;
				opacity: 1;
			}
		}


	}
	

    // LINE WORK

    @keyframes dash {
        from {
          stroke-dashoffset: 1000;
        }
        to {
          stroke-dashoffset: 0;
        }
      }
      path{
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
      }
      .animate{
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          animation: dash 2s forwards;
      }
      .is-selected path{
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          animation: dash 2s forwards;
	  }

	@keyframes fade-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@keyframes pulse {
		0% {
			fill: transparent;
		}
		100% {
			fill: inherit;
		}
	}
	  
	// FIRST CIRCLE
	.first-circle {
		position: absolute;
		top: 0px;
		left: calc(50% - 20px);
		z-index: 2;
		background: white;
		transition: all .3s ease-in-out;
		animation: fade-in 1.5s ease-in-out;
		circle {
			transition: all .3s ease-in-out;
			opacity: 1;
		}
	}

	.first-circle.active-circle {
		circle {
			-webkit-animation: pulse 1.5s infinite ease-in-out alternate-reverse;
			animation: pulse 1.5s infinite ease-in-out alternate-reverse;
		}
	}

    //   TOP LINE
	.top-line{
        text-align: center;
		position: relative;
        z-index: 1;
        svg {
            height: 300px;
        }
	}

	// MIDDLE LINE
	.middle-line {
		text-align: center;
		position: relative;
		z-index: -1;
	}

    //   BOTTOM LINE
      .bottom-line {
		position: relative;
        transition:0.3s;
        text-align: center;
		&:after{
			display: block;
			content: "";
			height: 33px;
			width: 100%;
			position: absolute;
			z-index: 1;
			background: white;
		}
		.bottom-circle{
			position: absolute;
			top: 99%;
			left: 50%;
			transform: translateX(-50%);
			z-index: 10;
		}
	}


	// FULL LENGTH SVG

	.full-length-svg {
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: -1;
		left: calc(50% - 20px);
	}
	

	//  CONTENT BAR 

	.content-bar {
		padding: 70px 0;
		margin-bottom: 150px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		position: relative;
		z-index: 10;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0,0,0,0.5);
			z-index: -1;
		}
		blockquote{
			max-width: 100%;
			line-height: 1.2;
		}
		p{
			text-align: right;
			margin-bottom: 0;
		}

	}


	// LAST SECTION REMOVE MARGIN
	section.journey-block:last-of-type {
		.content-bar, .journey-item {
			margin-bottom: 0px;
		}
	}



	// BOTTOM SECTION
	.bottom-section{
		position: relative;
		z-index: 1;
		background: white;
		margin-top: 33px;
		padding-bottom: 100px;
		text-align: center;
		.journey-logo{
			padding: 60px 0;
			text-align: center;
			img{
				max-height: 140px;
			}
		}
	
		.links{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			max-width: 1000px;
			margin: 0 auto;
		}
	
		.links a{
			padding: 16px 0;
			font-size: 2.7rem;
			&:first-of-type{
				@extend .yellow-button;
				min-width: 290px;
			}
	
			&:nth-of-type(2){
				@extend .red-button;
				min-width: 290px;

			}
	
			&:nth-of-type(3){
				@extend .teal-button;
				min-width: 290px;

			}
		}
	}


// height media queries

// @media screen and (orientation:landscape) {
// 	.page-template-page-journey .journey-sub-items h3{
// 		display: none;
// 	}

// 	.page-template-page-journey .journey-sub-item-sub-content{
// 		padding: 20px 0 0 0;
// 	}

// 	.page-template-page-journey .journey-sub-item-sub-content .journey-subItem-image{
// 		display: none;
// 	}

// 	.page-template-page-journey .journey-sub-item-sub-content h4{
// 		margin-bottom: 10px;
// 	}
// }




	// RESPONSIVE 
	@media (max-width: $xl) {
		.journey-sub-items-sub{
			padding: 0;
		}
		.internal-page .hero h1{
			font-size: 5rem;
		}
		.journey-title h2{
			font-size: 6rem;
		}
		.journey-title h3{
			font-size: 2rem;
		}
	}

	@media (max-width: $lg) {
		blockquote {
			font-size: 5rem;
		}

		.bottom-section .links {
			justify-content: space-between;
			a {
				min-width: 150px;
				text-align: center;
				margin: 0px 0px 15px 0px;
			}
		}

		.bottom-section .links a:first-of-type, 
		.bottom-section .links a:nth-of-type(2),
		.bottom-section .links a:nth-of-type(3) {
			font-size: 2rem;
			min-width: 200px;
		}
	}

		
	@media (max-width: $md) {

		section.hero {
			background-attachment: initial;
		}

		.bottom-section .links {
			flex-direction: column;
			align-items: center;
			a {
				
				text-align: center;
				margin-bottom: 25px;
				min-width: 300px;
			}
		}

		.bottom-section .links a:first-of-type, 
		.bottom-section .links a:nth-of-type(2),
		.bottom-section .links a:nth-of-type(3) {
			min-width: 300px;
		}

		.journey-sub-item-sub-content {
			padding: 25px 0px 0px 0px;
			.flex {
				flex-direction: column;
			}
			h4 {
				margin-bottom: 15px;
				line-height: 1.2;
			}
			.journey-subItem-image{
				margin: 20px 0 10px;
			}
			.journey-subItem-image img{
				display: block;
			}
			.journey-subItem-content {
				width: 100%;
				margin-top: 15px;
			}
		}

		.journey-sub-items {
			flex-direction: column;
			min-height: 40px;
			padding-top: 110px;
			li {
				width: 100%;
				opacity: 0;
				display: none;
			}
			li.is-selected {
				opacity: 1;
				display: flex;
			}
			.title-circle {
				display: none;
				align-items: center;
				justify-content: center;
				h3 {
					margin-top: 0px;
					display: none;
					margin-left: 20px;
				}
			}
		}

		.journey-sub-items-sub {
			h4 {
				text-align: center;
			}
			li {
				padding: 85px 0px 0px 0px;
			}
			img {
				display: none;
			}
		}

		.internal-page .hero h1{
			font-size: 4.5rem;
		}

		.journey-sub-item-sub-content h4{
			margin-bottom: 0;
			text-align: center;
			font-size: 3rem;
		}

		.journey-title h2{
			font-size: 7rem;
		}

		.stay-small {
			position: fixed !important;
			top: 145px !important;
		}
	

	}


	@media (max-width: $sm) {

		.internal-page .hero h1 {
			font-size: 3.5rem;
			padding: 0px 20px;
		}

		blockquote {
			font-size: 3rem;
		}

		.content-bar {
			padding: 50px 0px;
		}

		.journey-title {
			margin-bottom: 100px;
		}

		.journey-title h2 {
			font-size: 4rem;
		}

		.journey-title h3 {
			font-size: 1.8rem;
		}

		.journey-sub-item-sub-content .journey-subItem-content p,  .journey-sub-item-sub-content .journey-subItem-content a{
			font-size: 1.5rem;
		}

		.alignfull .wrapper p {
			font-size: 2rem;
			
		}

		.alignfull .wrapper p:last-of-type span {
			font-size: 1.2rem !important;
		}

		.journey-sub-item-sub-content .journey-subItem-image{
			margin: 10px 0 10px;
		}

		.journey-sub-item-sub-content .journey-subItem-image{
			width: 170px;
			height: 170px;
		}

		.journey-sub-item-sub-content h4{
			font-size: 2.7rem;
		}

		.stay-small {
			position: fixed !important;
			top: 95px !important;
		}


}

@media (max-width: $xs) {

	section.hero{
		min-height: 60vh;
	}
}
/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 812px) 
  and (orientation: landscape){
  	.journey-sub-item-sub-content .journey-subItem-image{
  		display: none;
  	}

  	.journey-sub-item-sub-content{
  		padding: 5px 0 0 0;
  	}

  	.journey-sub-item-sub-content .journey-subItem-content{
  		margin-top: 10px;
	  }
	  
	.journey-sub-item-sub-content .journey-subItem-content p, 
	.journey-sub-item-sub-content .journey-subItem-content a{
  		font-size: 1.7rem;
  	}

  	.journey-sub-item-sub-content h4{
  		font-size: 2.8rem;
  	}

  }

}















