.internal-page .wp-block-column {
	margin-bottom: 40px;
	p{
		margin-bottom: 0;
	}
}
.internal-page, .category{
	h1{
		font-size: 4rem;
		text-align: center;
		font-weight: 400;
		padding: 50px 0px 0px 0px;
		line-height: 1.1;
	}

	.blog-date {
		text-align: center;
		font-size: 1.5rem;
		margin-top: 15px;
	}

	.internal-main-content{
		padding: 40px 0 60px;
		max-width: 850px;
		margin: 0 auto;
	}

	.hero{
		padding: 210px 0 40px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-color: $blue;
		h1{
			padding: 10px 0;
			background: rgba(255,255,255, 0.8);
		}
	}

	p{
		margin-bottom: 40px;
		line-height: 1.5;
		&:last-of-type{
			margin-bottom: 0;
		}
		img{
			margin: 20px;
		}

		.alignleft{
			margin-left: 0;
		}

		.alignright{
			margin-right: 0;
		}
	}

	.alignfull{
		margin-top: 60px;
		margin-bottom: 60px;
	}

	.red-bar{
		background: #DE3831;
		text-align: center;
		padding: 70px 0;
		font-size: 4rem;
		color: white;
		blockquote{
			font-size: 4rem;
			max-width: 100%;
			margin-bottom: 0;
		}
		cite{
			font-size: 2.4rem;
		}
		p{
			margin-bottom: 0;
			line-height: 1.4;
			font-size: 4rem;
		}
	}

	.accordion-container{
		padding:0;
		margin: 0px 0px 25px 0px;
		.accordion-item{
			border: 10px solid #8FCAE7;
			border-bottom: 0;
			padding: 25px 25px;
			transition: 0.3s;
			background: #E8E7E9;
			position: relative;
			cursor: pointer;
			h3{
				font-size: 3rem;
				line-height: 1.2;
			}
			&:before{
				display: none;
			}
			&:last-of-type{
				border-bottom: 10px solid #8FCAE7;
			}
			&:after  {
				content: "";
				width: 25px;
				height: 25px;
				background: url(img/down-arrow.svg);
				position: absolute;
				right: 25px;
				top: 30px;
				transition: transform .4s ease;
			}
		}
		.accordion-item.open{
			background: white;
			&:after {
				transform: rotate(180deg);
			}
		}
		.accordion-content{
			margin: 20px 0;
			display: none;
		}
	}

	.profile-pic {
		border-radius: 50%;
		margin-right: 25px;
		max-width: 120px;
		max-height: 120px;
	}
}

.checkerboard-container{
	margin-bottom: 50px;
	padding-top: 60px;
}

.checkerboard-item{
	justify-content: space-between;
	align-items: stretch;
	min-height: 350px;
	&:nth-of-type(2n + 2){
		.checkerboard-content{
			order: -1;
		}
	}

	.checkerboard-content, .checkerboard-image{
		width: 50%;
	}

	.checkerboard-content{
		padding: 50px 50px;
		flex-direction: row;
		align-content: center;
		h3{
			margin-bottom: 20px;
		}
	}

	.checkerboard-image{
		background-size: cover;
		background-position: center;

	}

}
.wrapper + .checkerboard-container{
	border-top: 1px solid #ececec;
}

@media (max-width: $lg){
	.checkerboard-item .checkerboard-content, .checkerboard-item .checkerboard-image{
		width: 100%;
	}

	.checkerboard-item .checkerboard-image{
		min-height: 400px;
		order: 1;
	}

	.checkerboard-item:first-of-type .checkerboard-content{
		padding-top: 0;
	}

}

@media all and (max-width: $md ) {
  .internal-page p .alignright,
  .internal-page p .alignleft {
    margin: 40px auto;
  }

  .internal-page .red-bar{
  	padding: 50px 0;
  }

  .internal-page .red-bar p{
  	font-size: 3.4rem;
  }

  .internal-page .red-bar cite{
  	font-size: 2rem;
  }

}


@media (max-width: $sm ) {
  .internal-page .alignfull{
  	margin-top: 40px;
  	margin-bottom: 40px;
  }

  .internal-page p{
  	margin-bottom: 30px;
  }

  .internal-page .accordion-container .accordion-item{
	  padding: 20px;
	  h3 {
		  padding-right: 30px;
	  }
	  &:after {
		  right: 15px;
		  top: 25px;
	  }
  }

  .internal-page .accordion-container .accordion-item h3{
  	font-size: 2.4rem;
  	text-align: center;
  }

  .internal-page .accordion-container{
  	margin-top: 0px;
  }

  .internal-page .red-bar{
  	padding: 35px 0;
  }

  .internal-page .red-bar p{
  	font-size: 3.1rem;
  }

  .checkerboard-item .checkerboard-image{
		min-height: 250px;
	}

	.checkerboard-item .checkerboard-content{
		padding: 30px;
	}

	.checkerboard-item .checkerboard-content h3{
		margin-bottom: 10px;
	}

}

@media (max-width: $sm){
	.alignright, .alignleft, .internal-page p .alignright, .internal-page p .alignleft{
		margin-left  : calc( -100vw / 2 + 100% / 2 );
		margin-right : calc( -100vw / 2 + 100% / 2 );
		max-width: 100vw;
		width: 100vw;
		padding-left:0 !important;
		padding-right: 0 !important;
	}

	.internal-page .hero h1{
		font-size: 3rem;
	}
}
