$blue: #007AC9;
$yellow: #F0AB00;
$red: #DE3831;
$teal: #00A8B4;

html {
	font-size: 62.5%;
}

.wrapper {
	max-width: 1050px;
	width: 96%;
	margin: 0 auto;
}

.large-wrapper{
	width: 96%;
	margin: 0 auto;
	max-width: 1250px;
}

body {
	font-family: 'Open Sans', sans-serif;
	line-height: 1.5;
	color: #333333;
}

.sticky{
	position: sticky;
	top: 0;
	left:0;
		
}

.alignright{
	float: right;
}

.alignleft{
	float: left;
}

.aligncenter{
	display: block;
	text-align: center;
	margin: 0 auto;
}

.alignwide {
     margin-left  : -80px;
     margin-right : -80px;
 }
.alignfull {
     margin-left  : calc( -100vw / 2 + 100% / 2 );
     margin-right : calc( -100vw / 2 + 100% / 2 );
     max-width: 100vw;
     width: 100vw;
     padding-left:0 !important;
     padding-right: 0 !important;

 }
 .alignfull img {
     width: 100vw;
 }

 blockquote{
 	font-size: 6rem;
 	font-weight: 100;
 	line-height: 1.1;
 	margin:0;
 	padding:0;
 	position: relative;
 	max-width: 500px;
 	margin-bottom: 40px;
 	p{
 		font-size: inherit;
 		display: inline;
 		font-weight: 100;
 		line-height: 1.3 !important;
 		padding: 0 40px;
 		position: relative;
	 	&:before, &:after{
	 		font-size: 4rem;
	 		position: absolute;

	 	}
	 	
	 	&:before{
	 		content: "❝";
	 		display: inline-block;
	 		margin-right: 5px;
	 		top: 0;
	 		left:0px;
	 	}

	 	&:after{
	 		content: "❞";
	 		display: inline-block;
	 		margin-left: 5px;
	 		bottom: 0;
	 		right: 0px;
	 	}
 	}
 }

 cite{
 	text-align: left;
 	font-size: 2.4rem;
 	font-style: normal;
 	font-weight: 100;
 }

 a{
 	transition: 0.3s;
 	color: #007AC9;
 }

 main ul li, main ol li{
 	list-style: none;
 	&:before{
 	color: $yellow;
 	}
 }


 main ol{
 	counter-reset: li;
 	padding: 0;
 }
 main ol li:before{
 	content: counters(li, ".")". ";
 	counter-increment: li; 
 }



 main ul li{

 	&:before{
 		content: "• ";
 		counter-increment: none;
 	}
 }

 table{
 	width: 100%;
 	margin-bottom: 15px;
 	tbody{
 		display: table;
 		width: 100%;
 	}
 }

 .tablepress tr {
	 height: 140px;
 }

 td{
 	border: 1px solid black;
 	vertical-align: top;
 	padding: 10px;
 	p{
 		margin-bottom: 5px;
 	}
 }

 sub a, sup a{
 	font-size: inherit;
 }

 hr{
 	margin: 30px 0; 
 	border-color: $red;
 }

.flex{
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

h1, h2, h3, h4, h5, p { 
	margin: 0px;
	padding: 0px;
}

p, li, a, ul, ol, table, input {
	font-size: 2rem;
}

ul li{
	list-style-type: none;
	&:before{

	}
}

img {
	max-width: 100%;
}

h1{
	font-size: 5rem;
	font-weight: bold;
}

h2{
	font-size: 4rem;
	font-weight: bold;
}

h3{
	font-size: 3rem;
	font-weight: bold;
}

h4{
	font-size: 3rem;
	font-weight: bold;
	color: $blue;
}

h5{
	font-size: 2.4rem;
	font-weight: bold;
	text-transform: uppercase;
}

h6{
	font-size: 2rem;
	text-transform: uppercase;
	font-weight: bold;;
}
b{
	font-weight: bold;
}

i{
	font-style: italics;
}

// Visually Hidden
.visuallyhidden:not(:focus):not(:active) {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
  }

button{
	background: none;
	border: none;
}

.fadeIn{
	opacity: 0;
}


.red-button, 
.yellow-button, 
.teal-button {
	background: $red;
	color: white;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 3rem;
	padding: 17px 40px;
	display: inline-block;
	text-decoration: none;
	border-radius: 5px;
	border: 3px solid transparent;
	min-width: 250px;
	text-align: center;
	&:hover, &:focus{
		border: 3px solid $red;
		background: white;
		color: $red;
	}
}

.yellow-button {
	background: $yellow;
	padding: 7px 40px;
	font-size: 2rem;
	&:hover, &:focus {
		border: 3px solid $yellow;
		background: white;
		color: $yellow;
	}
}

.teal-button {
	background: $teal;
	&:hover, &:focus {
		border: 3px solid $teal;
		background: white;
		color: $teal;
	}
}

.yellow-button{
	color: white;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 3rem;
	padding: 17px 40px;
	display: inline-block;
	text-decoration: none;
	border-radius: 5px;
	border: 3px solid transparent;
	&:hover, &:focus{
		border: 3px solid $yellow;
		background: white;
		color: $yellow;
	}
}

// .none{
// 	// display: none !important;
// 	position: relative !important;
// 	top: -10000px;
// }


// Mobile Breakpoints
$xl: 1145px;
$lg: 980px;
$md: 768px;
$sm: 600px;
$xs: 420px;


@media all and (max-width: $md ) {

  .alignright,
  .alignleft {
    float: none !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .profile-pic {
	width: auto !important;
	padding-left: 2% !important;
  }

  blockquote{
  	text-align: center;
  	max-width: 100%;
  	font-size: 4rem;
  	p{
  		padding: 0 25px;
  	}
  }

  blockquote p:after, blockquote p:before{
  	font-size: 3.3rem;
  }

  h3, h4{
  	font-size: 2.5rem;
  }

  h5{
  	font-size: 2rem;
  }

}

@media (max-width: $sm){
	a, li, ol, p, table, ul{
		font-size: 1.8rem;
	}
}




