
.blog, .archive, .search, .search-results, .category{
    .page-nav{
        display: block;
        width: 100%;
        text-align: center;
        a{
            color: black;
            font-weight: bold;
            text-decoration: none;
            display: inline-block;
            margin-left: 10px;
            &:first-of-type{
                margin-right: 0;
            }
        }
    }

    #maincontent{
        margin-bottom: 50px;
    }
}
.archive, .search, .search-results {
    #maincontent {
        padding: 50px 0px 0px 0px;
    }

    h1 {
        padding-left: 15px;
        margin-bottom: 50px;
    }

    .content {
        width:100%;

        // margin-right: 50px;

        .sub-page a {
            min-width: auto;
        }
    }

    .sidebar {
        background: #e8e7e9;
        width: 25%;
        padding: 15px;
        margin-bottom: 15px;
    }

    .sidebar-list {
        list-style: none;
        padding-left: 0px;
        margin-top: 0px;
        ul {
            padding-left: 15px;
        }
        li {
            margin-bottom: 15px;
        }
        li:before {
            display: none;
        }
        h3 {
            line-height: 1.3;
        }
        input[type="submit"].searchsubmit {
            background: $red;
            color: white;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 2rem;
            padding: 5px 25px;
            display: inline-block;
            text-decoration: none;
            border-radius: 5px;
            border: 3px solid transparent;
            min-width: auto;
            text-align: center;
            &:hover, &:focus{
                border: 3px solid $red;
                background: white;
                color: $red;
            }
        }
    
        input[type="search"] {
            border: 2px solid $red;
            padding: 10px;
            margin: 0px 0px 15px 0px;
            max-width: 175px;
        }
    }


@media (max-width: $lg) {
    .sidebar-list input[type=search] {
        max-width: 120px;
    }


}



@media (max-width: $md) {

    .content, .sidebar {
        width: 100%;
        margin-right: 0px;
    }

    .sidebar-list input[type=search] {
        max-width: 250px;
    }


}



@media (max-width: $sm) {

    #maincontent {
        padding: 25px 0px 0px 0px;
    }

    h1 {
        font-size: 3rem;
        padding-left: 0px;
        margin-bottom: 25px;
    }






}







}