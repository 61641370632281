.sub-pages-container{
	justify-content: center;
	margin:0;
	padding:0;
	margin-top: 50px;
	li{
		background: #E8E7E9;
		border-radius: 5px;
		padding: 20px 0 30px;
		width: 31.3%;
		border: 1px solid transparent;
		transition: 0.3s;
		margin: 0 1% 40px;
			 &:hover, &:focus{
				border: 1px solid #979797;
				.teal-button{
					border: 3px solid $teal;
					background: white;
					color: $teal;
				}
			}
			a{
				text-decoration: none;
			}
		h3{
			margin: 0 30px 30px;
			word-break: break-word;
			line-height: 1.1;
			min-height: 66px;
			font-size: 3rem;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
		}
		a:not(.teal-button){
			width: 100%;
			height: 100%;
			display: block;
			color: black;
			text-align: center;
			text-decoration: none;
		}
		&:before{
			display: none;
		}

		img{
			display: block;
			width: 100%;
			max-height: 303px;
			object-fit: cover;
		}

		.teal-button{
			margin: 30px 30px 0;
			display: block;
			text-align: center;
			padding-left: 0;
			padding-right:0;
		}
	}

}

@media (max-width: $lg){
	.sub-pages-container li{
		max-width: 480px;
		width: auto;
		flex-basis: 480px;
		margin: 0 auto 50px;
	}
}

@media (max-width: $md){
	.sub-pages-container li{
		max-width: 480px;
	}
}

@media (max-width: $sm){
	.sub-pages-container li .teal-button{
		padding-left:0;
		padding-right:0;
		font-size: 2rem;
		margin: 20px 15px 0;
		padding: 5px;
	}

	.sub-pages-container li h3{
		font-size: 2.5rem;
		margin: 0 15px 20px;
		min-height: auto;
	}

	.sub-pages-container li{
		padding: 15px 0 20px;
	}
}