
.error404 {

    .hero404 {
        background: url(img/journey-door.png);
    }

    #maincontent .wrapper{
        max-width: 700px;
    }

    .internal-main-content404 {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        text-align: center;
    }

    form {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    input[type="submit"].searchsubmit {
        background: $red;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 2rem;
        padding: 10px 40px;
        display: inline-block;
        text-decoration: none;
        border-radius: 5px;
        border: 3px solid transparent;
        min-width: 150px;
        text-align: center;
        &:hover, &:focus{
            border: 3px solid $red;
            background: white;
            color: $red;
        }
    }

    input[type="search"] {
        border: 2px solid $red;
        padding: 10px;
        margin: 15px;
        flex-grow: 1;
        border-radius: 5px
    }

}