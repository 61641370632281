.skiplink {
    position: absolute;
    top: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
.skiplink:focus{
		top: 40px;
  width: auto;
  height: auto;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-size: 2rem;
  z-index: 10;
  padding: 10px;
  display: inline-block;
}

header{
	top: -300px;
	width: 100%;
}

header.fixed, .top-nav.fixed{
	position: fixed;
	top:0;
	z-index: 110;
	.top-nav--buttons a{
		font-size: 1.6rem;
		padding: 10px 10px;
	}

	.main-menu li .sub-menu{
		top: calc(100% + 20px);
	}


	.logo img{
		height: 50px;
	}

	.main-nav{
		padding: 25px 0 0;
		max-height: 65px;
		.logo{
			padding: 0 10px 5px 5px;
			border-radius: 5px;
		}
	}

	.main-nav .main-menu li a{
		margin-bottom: 23px;
		font-size: 1.8rem;
	}

	.main-nav .main-menu .sub-menu li a{
		margin-bottom: 0;
		font-size: 1.8rem;
	}
	p{
		font-size: 1.4rem;
		transition: 0.3s;
	}
}

.top-nav.fixed{
	padding: 8px 0;
}
.top-height{
	padding-top: 165px;
}

.admin-bar .fixed{
	top: 32px;
}

.top-nav{
	background: $blue;
	padding: 10px 0;
	color: white;
	top: -300px;
	width: 100%;
	ul{
		margin:0;
		padding:0;
	}
	.wrapper{
		flex-wrap: nowrap;
	}
	p{
		font-size: 1.6rem;
		margin-right: 50px;
	}
	a{
		color: white;
	}
	.wrapper{
		align-items: center;
		justify-content: space-between;
	}
	.top-nav--buttons{

		ul{
			display: flex;
		}
		a{
			min-width: 200px;
			padding: 13px 10px;
			color: white;
			text-decoration: none;
			display: inline-block;
			font-size: 1.8rem;
			line-height: 1;
			border-radius: 5px;
			text-align: center;
			text-transform: uppercase;
			font-weight: bold;
			transition: 0.3s;
			border: 2px solid transparent;
		}
		li{
			list-style: none;
			border-radius: 5px;
			&:first-of-type{
			background: $yellow;
			margin-right: 10px;
			
				&:hover a, &:focus a{
					border: 2px solid $yellow;
					color: $yellow;
					background: white;
				}
			}
			&:last-of-type{
				background: $red;
				&:hover a, &:focus a{
					border: 2px solid $red;
					color: $red;
					background: white;
				}
			}
		}
	}
}

.main-nav{
	background: white;
	padding: 35px 0 0;
	max-height: 85px;
	position: relative;
	z-index: 100;
	transition: 0.3s;
	.wrapper{
		justify-content: space-between;
	}
	.logo{
		padding: 0 25px 10px 25px;
		background: white;
		z-index: 1;
		position: relative;
		img{
			height: 69px;
			transition: 0.3s;
		}
	}
	.main-menu > ul{
		display: flex;
	}
	a{
		text-decoration: none;
	}

	.menu-button{
	  display: none;
	  width: 45px;
	  height: 31px;
	  position: relative;
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	  -webkit-transition: .5s ease-in-out;
	  -moz-transition: .5s ease-in-out;
	  -o-transition: .5s ease-in-out;
	  transition: .5s ease-in-out;
	  cursor: pointer;
	}

	.menu-button span {
	  display: block;
	  position: absolute;
	  height: 3px;
	  width: 100%;
	  background: black;
	  border-radius: 2px;
	  opacity: 1;
	  left: 0;
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	  -webkit-transition: .25s ease-in-out;
	  -moz-transition: .25s ease-in-out;
	  -o-transition: .25s ease-in-out;
	  transition: .25s ease-in-out;
	}

	.menu-button span:nth-child(1) {
	  top: 0px;
	}

	.menu-button span:nth-child(2) {
	  top: 12px;
	}

	.menu-button span:nth-child(3) {
	  top: 24px;
	}

	.menu-button.open span:nth-child(1) {
	  top: 18px;
	  -webkit-transform: rotate(135deg);
	  -moz-transform: rotate(135deg);
	  -o-transform: rotate(135deg);
	  transform: rotate(135deg);
	}

	.menu-button.open span:nth-child(2) {
	  opacity: 0;
	  left: -60px;
	  display: none;
	}

	.menu-button.open span:nth-child(3) {
	  top: 18px;
	  -webkit-transform: rotate(-135deg);
	  -moz-transform: rotate(-135deg);
	  -o-transform: rotate(-135deg);
	  transform: rotate(-135deg);
	}



	.main-menu{
		ul{
			line-height: 1;
			margin:0;
			padding:0;
		}
		li{
			line-height: 1;
			position: relative;
			a{
				color: black;
				line-height: 1;
				text-shadow: 0.1px 0.1px 0 black, -0.1px -0.1px 0 black;
				margin: 0 10px;
				padding-bottom: 30px;
				&:hover, &:focus, &:active{
					color: $teal;
					text-shadow: none;
				}
			}
			.sub-menu{
				display: none;
				position: absolute;
				top: calc(100% + 30px);
				left: -30px;
				background: white;
				padding: 20px 30px;
				min-width: 240px;
				transition: 0.3s;
				z-index: 100;
				box-shadow: 0 2px 1px rgba(0,0,0,0.3);
				li a{
					text-shadow: none;
					padding: 7px 0;
					display: block;
					line-height: 1.2;
				}
			}
		}

		li:hover, li.active{
			.sub-menu{
				display: block;
			}
		}
	}
}

@media (max-width: $xl){

	.main-nav{
		transition: 0s;
	}

	.top-nav.fixed .main-nav.top-height, header.fixed .main-nav.top-height, .main-nav.top-height{
		padding-top: 58px;
	}
	.main-menu{
		display: none;
		position: absolute;
		top: 100%;
		background: white;
		left:0;
		right: 0;
		z-index: 100;
	}

	.main-nav{
		position: relative;
	}
	.main-nav .main-menu>ul{
		flex-direction: column;
		padding: 20px 30px;
		
	}

	.menu-item-has-children{
		&:after{
			content: "\f054";
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			font-size: 1.2rem;
			line-height: 20px;
			width: 20px;
			text-align: center;
			padding: 3px;
			border: 2px solid black;
			border-radius: 50%;
			display: inline-block;
			top:0;
			position: absolute;
			right: 0;
			transition: 0.3s;
		}
	}

	.show{
		&:after{
			transform: rotate(-90deg);
		}

	}
	.main-nav .main-menu li{
		width: 100%;
	}

	.main-nav .main-menu li a{
		display: inline-block;
	}
	.main-nav{
		padding: 10px 0;
		max-height: unset;
		.wrapper{
			align-items: center;
		}
		.logo{
			padding: 0;
		 	img{
				max-height: 60px;
			}
		}
	}

	.main-nav .menu-button{
		display: block;
	}

	.main-nav .main-menu li:hover, .main-nav .main-menu li.active{
		.sub-menu{
			display: none;
		}
	}

	.main-nav .main-menu li{
		padding-bottom: 15px;
	}

	.main-nav .main-menu li a{
		padding-bottom: 10px;
	}

	.main-nav .main-menu li .sub-menu{
		position: static;
		padding:0 30px;
		box-shadow: none;
	}

	.main-nav .main-menu li.show > a{
		color: $teal;
	}
	

}

@media (max-width: $md){

	.top-nav .wrapper{
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;
	}

	.top-nav p{
		margin-right: 0;
		margin-bottom: 15px;
	}

	.main-nav.top-height{
		padding-top: 107px;
	}

}

@media (max-width: $sm){
	.top-nav .top-nav--buttons a{
		min-width: 170px;
	}

	.top-nav .top-nav--buttons a, .top-nav.fixed .top-nav--buttons a, header.fixed .top-nav--buttons a{
		padding: 8px;
		font-size: 1.4rem;
	}

	.home-stats .flickity-viewport{
		height: 405px;
	}

	.top-nav p, .top-nav.fixed p, header.fixed p{
		font-size: 1.1rem;
		margin-bottom: 6px;
		a {
			font-size: 1.1rem;
		}
	}

	.top-nav .top-nav--buttons li:first-of-type, .top-nav.fixed .top-nav--buttons li:first-of-type, header.fixed .top-nav--buttons li:first-of-type{
		margin-bottom: 6px;
	}

	.main-nav .logo img{
		max-height: 40px;
	}
	.top-nav .top-nav--buttons li {
		height: 34px;
	}
}

@media (max-width: $xs){
	.top-nav .top-nav--buttons ul{
		flex-direction: column;
	}

	.top-nav .top-nav--buttons li:first-of-type{
		margin-right: 0;
		margin-bottom: 6px;
	}

	

	.top-nav .top-nav--buttons a{
		width: 100%;
	}

	.top-nav--buttons, .top-nav .top-nav--buttons li, .top-nav .top-nav--buttons a, .top-nav .top-nav--buttons ul{
		width: 100%;
	}
}