
footer{
    font-weight: 100;
    color: #333333;
    padding: 70px 0 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    
    a, h6{
    color: #ffffff;
    }

    h6{
        text-transform: capitalize;
        font-weight: 100;
        margin: 0;
        padding: 0;
    }
    
    p{
        color: #A6BCC6;
    }

    .footer-safe-space{
        max-width: 200px;
        margin: 0 auto;
        flex-wrap: nowrap;
        margin-bottom: 20px;
        img{
            margin-right: 10px;
        }
        p{
            font-size: 1.2rem;

        }
    }

    .footer-title{
        color: #ffff;
        font-weight: bold;
    }        

    .footer-logo-container ul{
        display: flex;
        position: relative;
        margin: 0 auto;
        padding: 0 0 40px;
        align-items: center;
        justify-content: center;
        li{
            width: 25%;
            text-align: center;
        }
        li img{
            max-width: 120px;
        }
    }

    .footer-wrapper{
        max-width: 810px;
        
    } 


    
    .footer-address{
        text-align: center;
        display: flex;
        flex-direction: column;
        margin: 0 0 20px;
    
        }

    .footer-copyright{
        text-align: center;
        margin-bottom: 20px;
      
        }

    .footer-bottom-container{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 52px;

        
        .social-container{
            display: flex;

            .social-icons:last-of-type a{
                margin-right: 0;
            }
            
            .social-icons a{
                border: 4px solid white;
                border-radius: 50%;
                line-height: 45px;
                width: 50px;
                text-align: center;
                transition: 0.3s;
                margin-right: 10px;
                display: inline-block;
                &:hover, &:focus{
                    border: 4px solid $yellow;
                    color: $yellow;
                    .fab{
                        color: $yellow;
                    }
                }
        
                .fab{
                    transition: 0.3s;
                    font-size: 2rem;
                    color: white;
                    text-align: center;
                    width: 20px;
                    height: 20px;   
                }   
     
        }
    }
        .footer-links-container{
            display: flex;
            font-weight: 100;
            margin-left: 38px;
            .footer-links::before{
                content: "  \007C";
                color: white;
                font-size: 2rem;
                padding: 0 14px;
                }
            .footer-links:first-child:before{
                display: none;
                }    
        }
    }
}

// ====== media queries


@media (max-width: $xl){
        .footer-logo-container {
        

        .footer-address{
            padding: 0 168px;
            margin-bottom: 32px;
        }

        .footer-copyright{
            margin-bottom: 45px;
        }
    }
}

@media (max-width: $lg){
    .footer-address{
        margin-bottom: 32px;
    }
}

@media (max-width: $sm){

  footer .footer-logo-container ul{
        flex-wrap: wrap;
        max-width: 350px;
        padding: 0;
        li{
          width: 50%;
          text-align: center;
          margin-bottom: 30px;
        }
    }

    footer .footer-copyright{
        margin-bottom: 40px;
    }

    .footer-bottom-container{
        flex-direction: column;
        .social-container{
            margin-bottom: 16px;
        }
        .footer-links-container{
            margin: 0 !important;
            flex-wrap: wrap;
            justify-content: center;

        }
    }
}
@media (max-width: $xs){
     .footer-address{
        margin: 0 0 33px 0 !important;
        padding: 0 7px !important;
    }
}